import jQuery from 'jquery';
window.jQuery = jQuery;
import 'bootstrap';

!(function ($) {
	var bsabb = {
		init: () => {
			bsabb.mobile.init();
			bsabb.archiv.init();
			bsabb.organigram.init();
			bsabb.theme.init();
			bsabb.form.init();
			setTimeout(function () {
				$('body').addClass('show');
			}, 100);
		},
		mobile: {
			init: () => {
				(bsabb.isMobile = !1), //initiate as false
					(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
						navigator.userAgent
					) ||
						/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
							navigator.userAgent.substr(0, 4)
						)) &&
						(bsabb.isMobile = !0);
			},
		},
		archiv: {
			init: () => {
				(bsabb.a = $('.archiv').eq(0)),
					0 < bsabb.a.length && ((bsabb.c = $('.label', bsabb.a)), bsabb.c.on('click', bsabb.archiv.toggle)),
					$('.archiv .download').on('click', (e) => {
						$(e.currentTarget).toggleClass('hide');
					});
			},
			toggle: function (i) {
				i.stopPropagation();
				i.preventDefault();
				i.stopImmediatePropagation();
				bsabb.cb = $(i.currentTarget).find('input');
				bsabb.v = bsabb.cb.val();
				bsabb.cb.attr('checked') ? bsabb.cb.removeAttr('checked') : bsabb.cb.attr('checked', 'true');
				$('div.' + bsabb.v).toggleClass('disabled');
			},
		},

		organigram: {
			init: () => {
				(bsabb.a = $('.organigram').eq(0)), 0 < bsabb.a.length && ((bsabb.c = $('.label', bsabb.a)), bsabb.c.on('click', bsabb.organigram.toggle), (bsabb.t = $('#bsabb--organigram').find('.tab').on('click', bsabb.organigram.tab)));
			},
			toggle: (i) => {
				i.stopPropagation(),
					i.preventDefault(),
					i.stopImmediatePropagation(),
					$('.organigram .label').find('input').removeAttr('checked'),
					(bsabb.cb = $(i.currentTarget).find('input')),
					(bsabb.v = bsabb.cb.val()),
					bsabb.cb.attr('checked') ? bsabb.cb.removeAttr('checked') : bsabb.cb.attr('checked', 'true'),
					$('.organigram .container-fluid').addClass('disabled'),
					$('div.' + bsabb.v).removeClass('disabled');
			},

			tab: (e) => {
				e.stopPropagation(),
					e.preventDefault(),
					e.stopImmediatePropagation(),
					(bsabb.cl = $(e.currentTarget).attr('class').split(' ')[1]),
					$("input[value='" + bsabb.cl + "']")
						.parent()
						.trigger('click'),
					$([document.documentElement, document.body]).animate(
						{
							scrollTop:
								$('.container-fluid.' + bsabb.cl)
									.eq(0)
									.offset().top - 64,
						},
						500
					);
			},
		},

		theme: {
			init: () => {
				(bsabb.ts = $('.theme--switcher').eq(0)), bsabb.ts.on('click', bsabb.theme.toggle), bsabb.theme.storage.init();
			},
			toggle: (i) => {
				i.stopPropagation(),
					i.preventDefault(),
					i.stopImmediatePropagation(),
					$('body').attr('data-theme', (_, attr) => (attr == 'dark' ? null : 'dark')),
					$(i.currentTarget).text($(i.currentTarget).text() == 'Farbe' ? 'Kontrast' : 'Farbe'),
					(bsabb.theme.data = bsabb.theme.data == '0000000000color00000' ? '00000000dark00000000' : '0000000000color00000'),
					console.log(bsabb.theme.data),
					window.localStorage.setItem('-%|**h', btoa(bsabb.theme.data));
			},

			checkStorage: () => {
				if (window.localStorage.getItem('-%|**h') === null) {
					bsabb.theme.data = '0000000000color00000';
					window.localStorage.setItem('-%|**h', btoa(bsabb.theme.data));
				} else {
					bsabb.theme.data = atob(window.localStorage.getItem('-%|**h'));
				}

				if (bsabb.theme.data.length != 20) return false;

				console.log(bsabb.theme.data);

				if (bsabb.theme.data.includes('dark')) {
					if (bsabb.theme.data.indexOf('dark') != 8) return false;
					$('body').attr('data-theme', 'dark'), $(bsabb.ts).text('Farbe');
				} else {
					if (bsabb.theme.data.indexOf('color') != 10) return false;
					$('body').attr('data-theme', null), $(bsabb.ts).text('Kontrast');
				}

				return true;
			},

			storage: {
				init: () => {
					// check Foo
					if (!bsabb.theme.checkStorage()) {
						window.localStorage.removeItem('-%|**h');
						bsabb.theme.data = '0000000000color00000';
						window.localStorage.setItem('-%|**h', btoa(bsabb.theme.data));
					}
				},

				setter: () => {},
			},
		},

		// https://nikitahl.com/input-clear-button
		form: {
			init: () => {
				bsabb.input = document.querySelector('.clear-input');
				bsabb.clearButton = document.querySelector('.clear-input-button');
				bsabb.input.addEventListener('input', bsabb.form.handleInputChange);
				bsabb.clearButton.addEventListener('click', bsabb.form.handleButtonClick);
			},

			handleInputChange: (e) => {
				if (e.target.value && !bsabb.input.classList.contains('clear-input--touched')) {
					bsabb.input.classList.add('clear-input--touched');
				} else if (!e.target.value && bsabb.input.classList.contains('clear-input--touched')) {
					bsabb.input.classList.remove('clear-input--touched');
				}
			},

			handleButtonClick: (e) => {
				e.stopPropagation();
				e.stopImmediatePropagation();
				e.preventDefault();
				const o = e.target.parentNode.querySelector('.clear-input');
				o.value = '';
				o.focus();
				o.classList.remove('clear-input--touched');
			},
		},
	};

	$(function () {
		bsabb.init();
	});
	
})(jQuery);
